import { axiosInstance } from "@/services/axios"

export default {
  async getMeetings({ commit }) {
    await axiosInstance.get(`/api/student/meeting`)
      .then((res) => {
        commit('meetingsMutation', res.data)
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
        return
      })
  },

  async showMeeting({ commit }, payload) {
    await axiosInstance.get(`/api/student/meeting/${payload}`)
      .then((res) => {
        commit('meetingMutation', res.data)
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
        return
      })
  },

  async loginMeeting({ commit }, payload) {
    await axiosInstance.get(`/api/student/meeting/time-in/${payload}`)
      .then((res) => {
        console.log(res.data);
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
        return
      })
  },

  async getEmbeds({ commit }) {
    await axiosInstance.get(`/api/student/embed`)
      .then((res) => {
        commit('embedsMutation', res.data)
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
        return
      })
  },

  async showEmbed({ commit }, payload) {
    await axiosInstance.get(`/api/student/embed/${payload}`)
      .then((res) => {
        commit('embedMutation', res.data)
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
        return
      })
  },

  async commentEmbed({ commit, dispatch }, payload) {
    await axiosInstance.post(`/api/student/embed/comment`, payload)
      .then(res => {
        console.log(res.data);
        dispatch('showCommentsEmbed', payload.id)
        // commit('embedMutation', res.data)
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
        return
      })
  },

  async showCommentsEmbed({ commit }, payload) {
    await axiosInstance.get(`/api/student/embed/comment/${payload}`)
      .then((res) => {
        commit('embedCommentsMutation', res.data)
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
        return
      })
  },

  async loginEmbed({ commit }, payload ) {
    console.log(payload);
    await axiosInstance.get(`/api/student/embed/login/${payload}`)
      .then((res) => {
        console.log(res.data);
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
      })
  },
}