import { middleware } from './user.middleware'

export const StudentRoutes = [
  {
    path: '/student',
    component: () => import('@/student/layouts/Main.vue'),
    redirect: { name: 'Student Dashboard' },
    meta: { middleware: [middleware] },
    children: [
      {
        path: '/student/course-overview',
        name: 'Student Course Overview',
        component: () => import('@/student/pages/Courses.vue'),
        meta: { middleware: [middleware] },
      },
      {
        path: '/student/dashboard',
        name: 'Student Dashboard',
        component: () => import('@/student/pages/Dashboard.vue'),
        meta: { middleware: [middleware] },
      },
      {
        path: '/student/my-courses',
        name: 'Student Courses',
        component: () => import('@/student/pages/Courses.vue'),
        meta: { middleware: [middleware] },
      },
      {
        path: '/student/course/:uuid',
        name: 'Student Course Details',
        component: () => import('@/student/pages/CourseDetails.vue'),
        meta: { middleware: [middleware] },
      },
      {
        path: '/student/material/:uuid',
        name: 'Student Material',
        component: () => import('@/student/pages/Material.vue'),
        meta: { middleware: [middleware] },
      },
      {
        path: '/student/session/:uuid',
        name: 'Student Embed Session',
        component: () => import('@/student/components/meeting/EmbedFrame.vue'),
        meta: { middleware: [middleware] },
      },
      {
        path: '/student/:type/assessment/:uuid',
        name: 'Student Assessment',
        component: () => import('@/student/pages/Assessment.vue'),
        meta: { middleware: [middleware] },
      },
      {
        path: '/student/meetings',
        name: 'Student Meetings',
        component: () => import('@/student/pages/Meetings.vue'),
        meta: { middleware: [middleware] },
      },
      {
        path: '/student/zoom/session/:uuid',
        name: 'Student Zoom Session',
        component: () => import('@/student/pages/ZoomSession.vue'),
        meta: { middleware: [middleware] },
      },
      {
        path: '/student/ticket',
        name: 'Student Ticket',
        component: () => import('@/student/pages/Ticket.vue'),
        meta: { middleware: [middleware] },
      },
      {
        path: '/student/profile',
        name: 'Student Profile',
        component: () => import('@/student/pages/Profile.vue'),
        meta: { middleware: [middleware] },
      },
      {
        path: '/student/exhibits',
        name: 'Student Exhibits',
        component: () => import('@/student/pages/Exhibits.vue'),
        meta: { middleware: [middleware] },
      },
      {
        path: '/student/exhibit/:uuid',
        name: 'Student Exhibit Material',
        component: () => import('@/student/pages/ExhibitMaterial.vue'),
        meta: { middleware: [middleware] },
      },
      {
        path: '/student/inbox',
        name: 'Student Inbox',
        component: () => import('@/student/pages/Inbox.vue'),
        meta: { middleware: [middleware] },
      },
      {
        path: '/student/leaderboard',
        name: 'Student Leaderboard',
        component: () => import('@/student/pages/Leaderboard.vue'),
        meta: { middleware: [middleware] },
      },
    ],
  },
  {
    path: '/student/booth/:name/:score',
    name: 'Student Booth Score',
    component: () => import('@/student/pages/BoothScore.vue'),
    meta: { middleware: [middleware] },
  },
  {
    path: '/student/zoom/:meeting_id/:password/:zoom_key/:zoom_secret/:email/:name',
    name: 'Student Zoom Frame',
    component: () => import('@/student/components/meeting/MeetingFrame.vue'),
    meta: { middleware: [middleware] },
  },
  {
    path: '/student/zoom-tab/:uuid',
    name: 'Student Zoom Frame Tab',
    component: () => import('@/student/components/meeting/MeetingFrameTab.vue'),
    meta: { middleware: [middleware] },
  },
  {
    path: '/student/zoom-ended',
    name: 'Student Zoom Ended',
    component: () => import('@/student/components/meeting/Ended.vue'),
    meta: { middleware: [middleware] },
  },
]