import { axiosInstance } from "@/services/axios"

export default {
  async getChatAction({ commit }, payload) {
    await axiosInstance.get(`/api/teacher/chat/?query=${payload.query}`)
      .then((res) => {
        commit('chatMutation', res.data)
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
        return
      })
  },
  async sendChatAction({ commit, dispatch }, payload) {
    await axiosInstance.post(`/api/teacher/chat/send`, payload)
      .then(() => {
        dispatch('showChatAction', payload.uuid)
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
        return
      })
  },

  async showChatAction({ commit, dispatch }, payload) {
    await axiosInstance.get(`/api/teacher/chat/${payload}`)
      .then(res => {
        commit('convoMutation', res.data)
        dispatch('getChatAction', {
          query: ''
        })
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
        return
      })
  },

}