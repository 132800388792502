import router from "@/router"

export function signinMutation(state, payload) {
	let keyObject = { token: payload.access_token, role: 'a' }

	localStorage.setItem('access_key', JSON.stringify(keyObject))
	state.user = payload
	router.push({ name: 'Admin Dashboard' })
}

export function signoutMutation(state) {
	localStorage.removeItem('access_key')
	state.user = null
	location.href = '/'
}

export function authMutation(state, payload) {
	state.user = payload
}

export function resetPageMutation(state) {
	state.date = ''
	state.query = ''
	state.status = ''
	state.count = 10
}

export function errorsMutation(state, payload) {
	state.errors = payload
	//--- clear after a sec
	setTimeout(() => {
		state.errors = []
	}, 3000)
}

export function studentsMutation(state, payload) {
	state.students = payload
}

export function studentMutation(state, payload) {
	state.student = payload
}

export function studentsPageMutation(state, payload) {
	state.students.current_page = payload
}

export function teacherMutation(state, payload) {
	state.teachers = payload
}

export function teacherPageMutation(state, payload) {
	state.teachers.current_page = payload
}

export function coursesMutation(state, payload) {
	state.courses = payload
}

export function courseMutation(state, payload) {
	state.course = payload
}

export function coursePageMutation(state, payload) {
	state.courses.current_page = payload
}

export function vouchersMutation(state, payload) {
	state.vouchers = payload
}

export function vouchersPageMutation(state, payload) {
	state.vouchers.current_page = payload
}

export function meetingsMutation(state, payload) {
	state.meetings = payload
}

export function meetingMutation(state, payload) {
	state.meeting = payload
}

export function ticketsMutation(state, payload) {
	state.tickets = payload
}

export function ticketsPageMutation(state, payload) {
	state.tickets.current_page = payload
}

export function dashboardMutation(state, payload) {
	state.dashboard = payload
}

export function sponsorsMutation(state, payload) {
	state.sponsors = payload
}
/**
 * 
 * @param {*} state 
 * @param {*} payload 
 */
export function resultsMutation(state, payload) {
	state.results = payload
}
export function resultsPageMutation(state, payload) {
	state.results.current_page = payload
}
export function emailCountMutation(state, payload) {
	state.email_count = payload
}

export function leaderboardsMutation(state, payload) {
	state.leaderboards = payload
}

export function rafflesMutation(state, payload) {
	state.raffles = payload
}

export function scoresMutation(state, payload) {
	state.scores = payload
}

export function studentRaffleMutation(state, payload) {
	state.student_raffle = payload
}

export function embedsMutation(state, payload) {
	state.embeds = payload
}

export function userCategoryMutation(state, payload) {
	state.user_category = payload
}

