import { axiosInstance } from "@/services/axios"

export default {
  async coursesGetAction({ commit }, payload) {
    const res = await axiosInstance.get(`/api/student/course/?
      page=${payload.page}
      &query=${payload.query}
      &date=${payload.date}
      &count=${payload.count}`)

    commit('coursesMutation', res.data)
  },
  async courseGetAction({ commit }, payload) {
    const res = await axiosInstance.get(`/api/student/course/${payload}`)
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
        return
      })
    commit('courseMutation', res.data)
  },
}