import { axiosInstance } from "@/services/axios"

export default {
  
  async getCoursesAction({ commit }, payload) {
    const res = await axiosInstance.get(`/api/teacher/course/?
    page=${payload.page}
    &query=${payload.query}
    &date=${payload.date}
    &count=${payload.count}`)

    commit('coursesMutation', res.data)
  },

  async addCourseAction({ commit, dispatch }, payload) {
    try {
      await axiosInstance.post('/api/teacher/course/store', payload)
      dispatch('getCoursesAction', {
        page: 0,
        query: '',
        date: '',
        count: 10
      })
    } catch (error) {
      commit('errorsMutation', error.response.data.errors)
    }  
  },

  async showCourseAction({ commit }, payload) {
    try {
      const res = await axiosInstance.get(`/api/teacher/course/show/${payload}`)
      commit('courseMutation', res.data)
    } catch (error) {
      commit('errorsMutation', error.response.data.errors)
    }  
  },

  async courseStatusAction({ commit, dispatch }, payload) {
    try {
      await axiosInstance.post(`/api/teacher/course/status`, payload)
      dispatch('showCourseAction', payload.uuid)
    } catch (error) {
      commit('errorsMutation', error.response.data.errors)
    }  
  },

  async deleteCourseAction({ commit }, payload) {
    try {
      await axiosInstance.delete(`/api/teacher/course/${payload}`)
    } catch (error) {
      commit('errorsMutation', error.response.data.errors)
    }  
  },

  async updateCourseAction({ commit, dispatch }, payload) {
    try {
      await axiosInstance.put(`/api/teacher/course/${payload.id}`, payload)
      dispatch('showCourseAction', payload.uuid)
    } catch (error) {
      commit('errorsMutation', error.response.data.errors)
    }  
  },

   async uploadImageCourseAction({ commit, dispatch }, payload) {
    try {
      await axiosInstance.post(`/api/teacher/course/upload-image`, payload.form, {
        headers: {
          'Content-Type' : 'multipart/form-data'
        }
      })
      dispatch('showCourseAction', payload.uuid)
    } catch (error) {
      commit('errorsMutation', error.response.data.errors)
    }  
  },
}


