import { axiosInstance } from "@/services/axios"

export default {
  async syncChat({ commit }, payload) {
    await axiosInstance.get(`/api/admin/chat/sync/${payload}`)
      .then((res) => {
        console.log(res.data);
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
        return
      })
  },

  async exportChats({ commit }, payload) {
    await axiosInstance.post(`/api/admin/chat/export`, payload , 
      {
        responseType: 'blob'
      }
    )
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
      })
      .then((res) => {
        console.log(res.data)
        let fileURL = URL.createObjectURL(new Blob([res.data]));
        let fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute('download', `@${new Date()}-chats.xlsx`)
        document.body.appendChild(fileLink)

        fileLink.click()
        fileLink.remove()
      })
  },
}