import { axiosInstance } from "@/services/axios"

export default {
  async topicsGetAction({ commit }, payload) {
    const res = await axiosInstance.get(`/api/student/topic/${payload}`)
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
        return
    })
    commit('topicMutation', res.data)
  },
}