import { axiosInstance } from "@/services/axios"

export default {
  async createTicket({ commit, dispatch }, payload) {
    await axiosInstance.post(`/api/student/ticket/store`, payload)
      .then((res) => {
        console.log(res.data);
        dispatch('getTickets')
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
      })
  },

  async getTickets({ commit }) {
    await axiosInstance.get(`/api/student/ticket`)
      .then((res) => {
        commit('ticketsMutation', res.data)
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
      })
  },
}