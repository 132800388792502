import router from "@/router"

export function signinMutation(state, payload) {
	let keyObject = { token: payload.access_token, role: 't' }

	localStorage.setItem('access_key', JSON.stringify(keyObject))
	state.user = payload
	router.push({ name: 'Teacher Courses' })
}

export function signoutMutation(state) {
	localStorage.removeItem('access_key')
	state.user = null
	location.href = '/'
}

export function authMutation(state, payload) {
	state.user = payload
}

export function errorsMutation(state, payload) {
	state.errors = payload
	//--- clear after 3 sec
	setTimeout(() => {
		state.errors = []
	}, 2000)
}

export function resetPageMutation(state) {
	state.date = ''
	state.query = ''
	state.status = ''
	state.count = 10
}

export function coursePageMutation(state, payload) {
	state.courses.current_page = payload
}
//---plural
export function coursesMutation(state, payload) {
	state.courses = payload
}
//--singular
export function courseMutation(state, payload) {
	state.course = payload
}

export function lessonsMutation(state, payload) {
	state.lessons = payload
}

export function lessonMutation(state, payload) {
	state.lesson = payload
}

export function topicMutation(state, payload) {
	state.topic = payload
}

export function assessmentMutation(state, payload) {
	state.assessment = payload
}

export function addChoicesMutation(state, payload) {
	state.assessment.questions.forEach((item) => {
		if (item.id == payload) {
			item.choices.push('')
		}
	})
}

export function removeChoiceMutation(state, payload) {
	state.assessment.questions.forEach((item) => {
		if (item.id == payload.id) {
			item.choices.splice(payload.index, 1)
		}
	})
}

export function studentsMutation(state, payload) {
	state.students = payload
}

export function progressMutation(state, payload) {
	state.progress = payload
}

export function progressPageMutation(state, payload) {
	state.progress.current_page = payload
}

export function chatMutation(state, payload) {
	state.chat = payload
}

export function convoMutation(state, payload) {
	state.convo = payload
}
