import { axiosInstance } from "@/services/axios"

export default {
  async accountImage({ commit }, payload) {
    await axiosInstance.post(`/api/student/account/update-image`, payload)
      .then(() => {
        console.log('success');
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
      })
  },

  async accountPersonal({ commit }, payload) {
    await axiosInstance.post(`/api/student/account/personal`, payload)
      .then(() => {
        console.log('success');
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
      })
  },

  async accountProfile({ commit }, payload) {
    await axiosInstance.post(`/api/student/account/profile`, payload)
      .then(() => {
        console.log('success');
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
      })
  },
 
}