import { axiosInstance } from "@/services/axios"

export default {
  async storeRaffle({ commit, dispatch }, payload) {
    await axiosInstance.post(`/api/admin/raffle`, payload)
    .then((res) => {
      console.log(res.data);
      dispatch('getRaffle')
    }).catch((err) => {
      commit('errorsMutation', err.response.data.errors)
    });
    
  },
  async getRaffle({ commit }) {
    await axiosInstance.get(`/api/admin/raffle`)
    .then((res) => {
      commit('rafflesMutation', res.data)
    }).catch((err) => {
      commit('errorsMutation', err.response.data.errors)
    });
  },

  async showRaffle({ commit }, payload) {
    await axiosInstance.get(`/api/admin/raffle/${payload}`)
    .then((res) => {
      commit('rafflesMutation', res.data)
    }).catch((err) => {
      commit('errorsMutation', err.response.data.errors)
    });
  },

  async showStudentRaffle({ commit }, payload) {
    await axiosInstance.get(`/api/admin/raffle/student/${payload}`)
    .then((res) => {
      commit('studentRaffleMutation', res.data)
    }).catch((err) => {
      commit('errorsMutation', err.response.data.errors)
    });
  },
}