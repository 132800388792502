import { axiosInstance } from "@/services/axios"

export default {
  async showAssessmentAction({ commit }, payload) {
    try {
      const res = await axiosInstance.get(`/api/teacher/assessment/${payload}`,)
      commit('assessmentMutation', res.data)
    } catch (error) {
      commit('errorsMutation', error.response.data.errors)
    }
  },
  async updateAssessmentAction({ commit, dispatch }, payload) {

    await axiosInstance.post(`/api/teacher/assessment/update`, payload)
      .then((res) => {
        console.log(res);
        dispatch('showAssessmentAction', payload.uuid)
      })
      .catch((error) => {
        commit('errorsMutation', error.response.data.errors)
      })
  },
  async addQuestionAction({ commit, dispatch }, payload) {
    try {
      await axiosInstance.post(`/api/teacher/assessment/store-question`, payload)
      dispatch('showAssessmentAction', payload.uuid)
    } catch (error) {
      commit('errorsMutation', error.response.data.errors)
    }
  },
  async updateQuestionAction({ commit, dispatch }, payload) {
    try {
      await axiosInstance.post(`/api/teacher/assessment/update-question`, payload)
      dispatch('showAssessmentAction', payload.uuid)
    } catch (error) {
      commit('errorsMutation', error.response.data.errors)
    }
  },
  async deleteQuestionAction({ commit, dispatch }, payload) {
    await axiosInstance.get(`/api/teacher/assessment/delete-question/${payload.id}`)
    .then((res) => {
      console.log(res.data);
      dispatch('showAssessmentAction', payload.uuid)
    })
    .catch((error) => {
      commit('errorsMutation', error.response.data.errors)
    })
  },
  async deleteAssessmentAction({ commit }, payload) {
    try {
      await axiosInstance.delete(`/api/teacher/assessment/${payload}`)
    
    } catch (error) {
      commit('errorsMutation', error.response.data.errors)
    }
  },
}