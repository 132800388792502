import { axiosInstance } from "@/services/axios"

export default {
  async getStudent({ commit }) {
    await axiosInstance.get(`/api/teacher/student`)
      .then((res) => {
        commit('studentsMutation', res.data)
      }).catch((err) => {
        commit('errorsMutation', err.response.data.errors)
      });
  },
  async showStudentInfo({ commit }, payload) {
    await axiosInstance.get(`/api/teacher/student/${payload}`)
      .then((res) => {
        commit('studentsMutation', res.data)
      }).catch((err) => {
        commit('errorsMutation', err.response.data.errors)
      });
  },

  async showStudentProgress({ commit }, payload) {
    await axiosInstance.get(`/api/teacher/student/progress/${payload.id}
    ?page=${payload.page}
    &count=${payload.count}`)
      .then((res) => {
        commit('progressMutation', res.data)
      }).catch((err) => {
        commit('errorsMutation', err.response.data.errors)
      });
  },

  async lessonProgress({ commit }, payload) {
    await axiosInstance.get(`/api/teacher/student/lesson-progress/${payload.course_uuid}/${payload.user_id}`)
      .then((res) => {
        commit('courseMutation', res.data)
      }).catch((err) => {
        commit('errorsMutation', err.response.data.errors)
      });
  },
}