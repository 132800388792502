import { axiosInstance } from "@/services/axios"

export default {
  async comment({ commit, dispatch }, payload) {
    await axiosInstance.post(`/api/student/comment`, payload)
      .then(() => {
        dispatch('comments', payload.id)
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
      })
  },
  async reply({ commit, dispatch }, payload) {
    await axiosInstance.post(`/api/student/reply`, payload)
      .then(() => {
        dispatch('comments', payload.topic_id)
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
      })
  },
  async comments({ commit }, payload) {
    await axiosInstance.get(`/api/student/comment/${payload}`)
      .then((res) => {
        commit('commentsMutation', res.data)
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
      })
  },
}