import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        dark: '#000000',
        secondary: '#ecf0f1',
        'secondary-1' : '#dff9fb',
        danger: '#c0392b',
        success: '#00A14B',
        'success-1': '#46B254',
        'success-2': '#9DD776',
        warning: '#FFC624',
        'warning-1': '#feda74',
        'waring-2': '#FFF5D8',
        primary: '#0D47A1',
        'primary-1': '#1565C0',
        'primary-2': '#1976D2',
        'primary-3': '#0F75BC',
        info: '#1E88E5'

      },
    },
  },
})