import state from './state'
import * as getters from './getters'
import * as main from './actions'
import * as mutations from './mutations'
import course from './actions/course'
import account from './actions/account'
import voucher from './actions/voucher'
import zoom from './actions/zoom'
import ticket from './actions/ticket'
import dashboard from './actions/dashboard'
import sponsor from './actions/sponsor'
import result from './actions/result'
import leaderboard from './actions/leaderboard'
import raffle from './actions/raffle'
import chat from './actions/chat'
import settings from './actions/settings'

export default {
  namespaced: true,
  getters,
  mutations,
  actions: {
    ...main,
    ...course,
    ...account,
    ...voucher,
    ...zoom,
    ...ticket,
    ...dashboard,
    ...sponsor,
    ...result,
    ...leaderboard,
    ...raffle,
    ...chat,
    ...settings
  },
  state
}