import { axiosInstance } from "@/services/axios"

export default {
  async updateTicket({ commit }, payload) {
    await axiosInstance.patch(`/api/admin/ticket/${payload.uuid}`, payload)
      .then((res) => {
        console.log(res.data);
        commit('ticketsMutation', res.data)
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
      })
  },

  async getTickets({ commit }, payload) {
    await axiosInstance.get(`/api/admin/ticket/?
      page=${payload.page}
      &status=${payload.status}`
    )
      .then((res) => {
        commit('ticketsMutation', res.data)
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
      })
  },

  async showTicket({ commit }, payload) {
    await axiosInstance.get(`/api/admin/ticket/${payload}`)
      .then((res) => {
        commit('ticketsMutation', res.data)
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
      })
  },

  async noteTicket({ commit }, payload) {
    await axiosInstance.post(`/api/admin/ticket/note`, payload)
      .then((res) => {
        commit('ticketsMutation', res.data)
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
      })
  },
}