import { axiosInstance } from "@/services/axios"

export default {
  async getSponsorsAction({ commit }) {
    await axiosInstance.get(`/api/student/topic/sponsor`)
      .then(res => {
        commit('sponsorsMutation', res.data)
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
        return
      })
  },

}