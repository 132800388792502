function middleware({ next }) {
  let obj = JSON.parse(localStorage.getItem('access_key'))
  if (!obj || !obj.token) {
    location.href='/login'
  }
  return next()
}

export {
  middleware,
}