import Vue from 'vue'
import VueRouter from 'vue-router'

import middlewarePipeline from './middleware-pipeline'

import { LandingRoutes } from './landing'
import { AdminRoutes } from './admin'
import { StudentRoutes } from './student'
import { TeacherRoutes } from './teacher'

Vue.use(VueRouter)

const routes = [
  ...LandingRoutes,
  ...StudentRoutes,
  ...TeacherRoutes,
  ...AdminRoutes,
  {
    path: '*',
    component: () => import('@/landing/pages/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];
    const ctx = { from, next, to };
    const nextMiddleware = middlewarePipeline(ctx, middleware, 1);

    return middleware[0]({ ...ctx, next: nextMiddleware });
  }
  return next();
  
});

export default router