import Vue from 'vue'

Vue.component('snackbar', () => import('./Snackbar.vue'))
Vue.component('vid', () => import('./material/Video.vue'))
Vue.component('url', () => import('./material/Url.vue'))
Vue.component('document', () => import('./material/Document.vue'))

//---Filter Components
Vue.component('search', () => import('./global-components/SearchField.vue'))
Vue.component('date', () => import('./global-components/SearchDate.vue'))
Vue.component('count', () => import('./global-components/ShowCount.vue'))
Vue.component('status', () => import('./global-components/Status.vue'))

Vue.component('course-paginate', () => import('./global-components/CoursePagination.vue'))
Vue.component('paginate', () => import('./GlobalPagination.vue'))

Vue.component('course-card', () => import('./global-components/CourseCard.vue'))
Vue.component('breadcrumbs', () => import('./global-components/Breadcrumbs.vue'))

Vue.component('loading-table', () => import('./global-components/TableLoader.vue'))
Vue.component('skeleton-text', () => import('./global-components/TextLoader.vue'))
Vue.component('skeleton-button', () => import('./global-components/ButtonLoader.vue'))

Vue.component('InfiniteLoading', require('vue-infinite-loading'));
