import { axiosInstance } from "@/services/axios"

export async function registerAction({ commit }, payload) {
  try {
    const res = await axiosInstance.post('/api/admin/register', payload)
    commit('signinMutation', res.data)
  } catch (error) {
    commit('errorsMutation', error.response.data.errors)
  }
}

export async function loginAction({ commit }, payload) {
  await axiosInstance.post('/api/admin/login', payload)
    .then((res) => {
      commit('signinMutation', res.data)
    }) 
    .catch(error => {
      commit('errorsMutation', error.response.data.errors)  
    })
}

export async function logoutAction({ commit }) {
  await axiosInstance.get('/api/admin/logout')
  commit('signoutMutation') 
}

export async function authAction({ commit }) {
  const res = await axiosInstance.get('/api/admin/details')
  .catch(error => {
    if(error.response.status === 401 || error.response.status === 500) {
      localStorage.removeItem('access_key')
      // alert('Relogin your account.')      
      // location.href='/login'
    }   
  })
  commit('authMutation', res.data) 
}

export async function postCredentials({ commit }, payload) {
  try {
    const res = await axiosInstance.post('/api/admin/credentials', payload)
    console.log(res.data);
  } catch (error) {
    commit('errorsMutation', error.response.data.errors)
  }
}

export async function emailCountAction({ commit }) {
  try {
    const res = await axiosInstance.post('/api/admin/email-count')
    console.log(res.data);
    commit('emailCountMutation', res.data)
  } catch (error) {
    commit('errorsMutation', error.response.data.errors)
  }
}