import { axiosInstance } from "@/services/axios"

export default {
  async showTopicAction({ commit }, payload) {
    try {
      const res = await axiosInstance.get(`/api/teacher/topic/show/${payload}`)
      commit('topicMutation', res.data)
    } catch (error) {
      commit('errorsMutation', error.response.data.errors)
    }  
  },
  async addTopicAction({ commit, dispatch }, payload) {
    try {
      await axiosInstance.post('/api/teacher/topic/store', payload.form)
      dispatch('showLessonsAction', payload.course_id)
    } catch (error) {
      commit('errorsMutation', error.response.data.errors)
    }  
  },
  async deleteTopicAction({ commit, dispatch }, payload) {
    try {
      await axiosInstance.delete(`/api/teacher/topic/${payload.topic_id}`)
      dispatch('showLessonsAction', payload.course_id)
    } catch (error) {
      commit('errorsMutation', error.response.data.errors)
    }  
  },
  async sortTopicsAction({ commit }, payload) {
    try {
      await axiosInstance.post('/api/teacher/topic/sort', payload)
    } catch (error) {
      commit('errorsMutation', error.response.data.errors)
    }  
  },
  async addTopicAssessmentAction({ commit }, payload) {
    try {
      await axiosInstance.post('/api/teacher/topic/store-assessment', payload)
    } catch (error) {
      commit('errorsMutation', error.response.data.errors)
    }  
  },
  async updateTopicAction({ commit, dispatch }, payload) {
    try {
      await axiosInstance.put(`/api/teacher/topic/${payload.id}`, payload)
      dispatch('showTopicAction', payload.uuid)
    } catch (error) {
      commit('errorsMutation', error.response.data.errors)
    }  
  },
   async statusTopicAction({ commit, dispatch }, payload) {
    try {
      await axiosInstance.get(`/api/teacher/topic/status/${payload.topic_id}`)
      dispatch('showLessonsAction', payload.course_id)
    } catch (error) {
      commit('errorsMutation', error.response.data.errors)
    }  
  },
  async updateMaterialAction({ commit, dispatch }, payload) {
    try {
      await axiosInstance.post('/api/teacher/topic/update-material', payload.form)
      dispatch('showTopicAction', payload.uuid)
    } catch (error) {
      commit('errorsMutation', error.response.data.errors)
    }  
  },
}