import { axiosInstance } from "@/services/axios"

export default {
  async vouchersGetAction({ commit }, payload) {
    const res = await axiosInstance.get(`/api/admin/voucher/?
      page=${payload.page}
      &query=${payload.query}
      &date=${payload.date}
      &count=${payload.count}`)
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
        return
      })

    commit('vouchersMutation', res.data)
  },

  async generateVoucherAction({ commit, dispatch }, payload) {
    await axiosInstance.post(`/api/admin/voucher/generate`, payload)
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
        return
      })
    dispatch('vouchersGetAction', {
      page: 0,
      query: '',
      date: '',
      count: 10
    })
  },

  async showVoucherDetails({commit}, payload) {
    await axiosInstance.get(`/api/admin/voucher/details/${payload}`)
    .then(res => {
      commit('vouchersMutation', res.data)
    })
    .catch(err => {
      commit('errorsMutation', err.response.data.errors)
      return
    })
  },

  async deleteVoucher({commit, dispatch}, payload) {
    await axiosInstance.get(`/api/admin/voucher/delete/${payload}`)
    .then(() => {
      dispatch('vouchersGetAction', {
        page: 0,
        query: '',
        date: '',
        count: 10
      })
    })
    .catch(err => {
      commit('errorsMutation', err.response.data.errors)
      return
    })
  }
}