const routes = [
  {
    subheader: 'DASHBOARD',
    list: [
      // {
      //   title: 'Leaderboard',
      //   icon: 'mdi-chart-areaspline',
      //   route: 'Student Leaderboard'
      // },
      {
        title: 'Courses Overview',
        icon: 'mdi-book-multiple-outline',
        route: 'Student Course Overview'
      },
      // {
      //   title: 'Dashboard',
      //   icon: 'mdi-view-dashboard-outline',
      //   route: 'Student Dashboard'
      // },
      // {
      //   title: 'My Courses',
      //   icon: 'mdi-book-multiple-outline',
      //   route: 'Student Courses'
      // },
      // {
      //   title: 'Meetings',
      //   icon: 'mdi-video-outline',
      //   route: 'Student Meetings'
      // },
      // {
      //   title: 'Inbox',
      //   icon: 'mdi-message-outline',
      //   route: 'Student Inbox'
      // },
      // {
      //   title: 'Exhibits',
      //   icon: 'mdi-gate-open',
      //   route: 'Student Exhibits'
      // },
      
    ],
  },
  {
    subheader: 'ACCOUNT',
    list: [
      {
        title: 'Ticket',
        icon: 'mdi-ticket-outline',
        route: 'Student Ticket'
      },
      {
        title: 'Profile',
        icon: 'mdi-account-outline',
        route: 'Student Profile'
      },
    ]
  }
]


export default {
  install(Vue) {
    Vue.prototype.$studentRoutes = routes
  }
}



