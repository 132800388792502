import Vue from "vue";
import Vuex from "vuex";
Vue.config.devtools = true;

Vue.use(Vuex);

import student from './student'
import admin from './admin'
import teacher from './teacher'

export default new Vuex.Store({
  modules: {
    student,
    teacher,
    admin
  }
})