export function userGetters (state) {
  return state.user
}

export function studentsGetters (state) {
  return state.students
}

export function teachersGetters (state) {
  return state.teachers
}

export function countGetters (state) {
  return state.count
}

export function vouchersGetters (state) {
  return state.vouchers
}

export function errorsGetters (state) {
  return state.errors
}