import { axiosInstance } from "@/services/axios"

export default {
  async getExhibits({ commit }) {
    await axiosInstance.get(`/api/student/exhibit`)
      .then((res) => {
        commit('exhibitsMutation', res.data)
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
      })
  },
  async showExhibit({ commit }, payload) {
    await axiosInstance.get(`/api/student/exhibit/${payload}`)
      .then((res) => {
        commit('exhibitsMutation', res.data)
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
      })
  },

  async storeBoothScore({ commit }, payload) {
    await axiosInstance.get(`/api/student/booth/score/${payload.name}/${payload.score}`)
      .then((res) => {
        console.log(res.data);
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
      })
  },
}