export default function () {
  return {
    //---Account
    user: null,

    dashboard: [],

    student: [],
    students: [],

    teachers: [],
    tickets: [],
    ticket_status: '',

    results: [],
    
    embeds: [],
    meetings: [],
    meeting: [],

    vouchers: [],
    sponsors: [],
    //---
    course: [],
    courses: [],
    
    email_count: 0,

    leaderboards: [],
    scores: [],
    raffles: [],
    student_raffle: [],

    user_category: [],

    //---Query Page
    date: '',
    status: '',
    query: '',
    count: 10,
    //---Error Handler
    errors: [],
  }
}