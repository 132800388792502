import { axiosInstance } from "@/services/axios"

export default {
  async getResults({ commit }, payload) {
    await axiosInstance.get(`/api/admin/result?page=${payload.page}`)
      .then((res) => {
        commit('resultsMutation', res.data)
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
        return
      })
  },
}