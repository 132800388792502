const routes = [
  {
    subheader: 'DASHBOARD',
    list: [
      {
        title: 'Dashboard',
        icon: 'mdi-view-dashboard-outline',
        route: 'Admin Dashboard'
      },
      {
        title: 'Courses',
        icon: 'mdi-book-multiple-outline',
        route: 'Admin Courses'
      },
      {
        title: 'Vouchers',
        icon: 'mdi-ticket-outline',
        route: 'Admin Vouchers'
      },
      // {
      //   title: 'Meetings',
      //   icon: 'mdi-video-outline',
      //   route: 'Admin Meetings'
      // },
      // {
      //   title: 'Meeting Embed',
      //   icon: 'mdi-video-outline',
      //   route: 'Admin Embed'
      // },
      // {
      //   title: 'Results',
      //   icon: 'mdi-chart-box-outline',
      //   route: 'Admin Results'
      // },
    ]
  },
  {
    subheader: 'ACCOUNTS',
    list: [
      {
        title: 'Students',
        icon: 'mdi-account-multiple-outline',
        route: 'Admin Student Account'
      },
      {
        title: 'Teachers',
        icon: 'mdi-account-multiple-outline',
        route: 'Admin Teacher Account'
      },
      // {
      //   title: 'Emails',
      //   icon: 'mdi-email-outline',
      //   route: 'Admin Emails'
      // },
    ]
  },
  {
    subheader: 'SETTINGS',
    list: [
      // {
      //   title: 'Gamifications',
      //   icon: 'mdi-controller-classic-outline',
      //   route: 'Admin Gamification'
      // },
      {
        title: 'System Settings',
        icon: 'mdi-cog-outline',
        route: 'Admin Settings'
      },
    ]
  },

]

export default {
  install(Vue) {
    Vue.prototype.$adminRoutes = routes
  }
}



