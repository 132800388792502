import { axiosInstance } from "@/services/axios"

export default {
  async sendChatAction({ commit, dispatch }, payload) {
    await axiosInstance.post(`/api/student/chat/send`, payload)
      .then(() => {
        dispatch('showChatAction', payload.uuid)
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
        return
      })
  },

  async showChatAction({ commit, dispatch }, payload) {
    await axiosInstance.get(`/api/student/chat/${payload}`)
      .then(res => {
        commit('chatMutation', res.data)
        dispatch('student/getSponsorsAction', {}, {root: true})
      
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
        return
      })
  },

}