import { axiosInstance } from "@/services/axios"

export default {
  async addSponsor({ commit }, payload) {
    await axiosInstance.post(`/api/admin/sponsor`, payload)
      .then((res) => {
        console.log(res.data);
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
      })
  },
  async getSponsors({ commit }) {
    await axiosInstance.get(`/api/admin/sponsor`)
      .then((res) => {
        console.log(res.data);
        commit('sponsorsMutation', res.data)
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
      })
  },
}