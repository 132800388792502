import { middleware } from './user.middleware'

export const AdminRoutes = [
  {
    path: '/admin/login',
    name: 'Admin Login',
    component: () => import('@/authentication/LoginAdmin.vue'),
  },
  {
    path: '/admin/registration',
    name: 'Admin Registration',
    component: () => import('@/authentication/RegisterAdmin.vue'),
  },
  {
    path: '/admin',
    component: () => import('@/admin/layouts/Main.vue'),
    redirect: { name: 'Admin Dashboard' },
    meta: { middleware: [middleware] },
    children: [
      {
        path: '/admin/dashboard',
        name: 'Admin Dashboard',
        component: () => import('@/admin/pages/Dashboard.vue'),
        meta: { middleware: [middleware] },
      },
      {
        path: '/admin/student-account',
        name: 'Admin Student Account',
        component: () => import('@/admin/pages/StudentAccount.vue'),
        meta: { middleware: [middleware] },
      },
      {
        path: '/admin/student/:uuid',
        name: 'Admin Student Details',
        component: () => import('@/admin/pages/StudentDetails.vue'),
        meta: { middleware: [middleware] },
      },
      {
        path: '/admin/teacher-account',
        name: 'Admin Teacher Account',
        component: () => import('@/admin/pages/TeacherAccount.vue'),
        meta: { middleware: [middleware] },
      },
      {
        path: '/admin/courses',
        name: 'Admin Courses',
        component: () => import('@/admin/pages/Courses.vue'),
        meta: { middleware: [middleware] },
      },
      {
        path: '/admin/course/:uuid',
        name: 'Admin Course Details',
        component: () => import('@/admin/pages/CourseDetails.vue'),
        meta: { middleware: [middleware] },
      },
      {
        path: '/admin/vouchers',
        name: 'Admin Vouchers',
        component: () => import('@/admin/pages/Vouchers.vue'),
        meta: { middleware: [middleware] },
      },
      {
        path: '/admin/voucher/:id',
        name: 'Admin Voucher',
        component: () => import('@/admin/pages/VoucherDetails.vue'),
        meta: { middleware: [middleware] },
      },
      {
        path: '/admin/results',
        name: 'Admin Results',
        component: () => import('@/admin/pages/Results.vue'),
        meta: { middleware: [middleware] },
      },
      {
        path: '/admin/gamification',
        name: 'Admin Gamification',
        component: () => import('@/admin/pages/Gamification.vue'),
        meta: { middleware: [middleware] },
      },
      {
        path: '/admin/leaderboard/:id',
        name: 'Admin Leaderboard',
        component: () => import('@/admin/pages/Leaderboard.vue'),
        meta: { middleware: [middleware] },
      },
      {
        path: '/admin/raffle/:id',
        name: 'Admin Raffle',
        component: () => import('@/admin/pages/Raffle.vue'),
        meta: { middleware: [middleware] },
      },
      {
        path: '/admin/result/:id',
        name: 'Admin Result Details',
        component: () => import('@/admin/pages/ResultDetails.vue'),
        meta: { middleware: [middleware] },
      },
      {
        path: '/admin/emails',
        name: 'Admin Emails',
        component: () => import('@/admin/pages/Emails.vue'),
        meta: { middleware: [middleware] },
      },
      {
        path: '/admin/meeting-embed',
        name: 'Admin Embed',
        component: () => import('@/admin/pages/Embed.vue'),
        meta: { middleware: [middleware] },
      },
      {
        path: '/admin/meetings/',
        name: 'Admin Meetings',
        component: () => import('@/admin/pages/Meetings.vue'),
        meta: { middleware: [middleware] },
        redirect: { name: 'Admin Meeting Lists' },
        children: [
          {
            path: 'lists',
            name: 'Admin Meeting Lists',
            component: () => import('@/admin/components/meeting/Lists.vue'),
            meta: { middleware: [middleware] },
          },
          {
            path: 'create',
            name: 'Admin Create Meeting',
            component: () => import('@/admin/components/meeting/Create.vue'),
            meta: { middleware: [middleware] },
          },
        ]
      },
      {
        path: '/admin/zoom/session/:uuid',
        name: 'Admin Zoom Session',
        component: () => import('@/admin/pages/ZoomSession.vue'),
        meta: { middleware: [middleware] },
      },
      {
        path: '/admin/settings/',
        name: 'Admin Settings',
        component: () => import('@/admin/pages/Settings.vue'),
        meta: { middleware: [middleware] },
        redirect: { name: 'Admin General Settings' },
        children: [
          {
            path: 'zoom-api',
            name: 'Admin Zoom Api',
            component: () => import('@/admin/pages/ZoomApi.vue'),
            meta: { middleware: [middleware] },
          },
          {
            path: 'recycle-bin',
            name: 'Admin Trash',
            component: () => import('@/admin/pages/TrashBin.vue'),
            meta: { middleware: [middleware] },
          },
          {
            path: 'sponsors',
            name: 'Admin Sponsors',
            component: () => import('@/admin/pages/Sponsors.vue'),
            meta: { middleware: [middleware] },
          },
          {
            path: 'general-settings',
            name: 'Admin General Settings',
            component: () => import('@/admin/pages/GeneralSettings.vue'),
            meta: { middleware: [middleware] },
            children: [
              {
                path: 'user-category',
                name: 'Admin User Category',
                component: () => import('@/admin/pages/UserCategory.vue'),
                meta: { middleware: [middleware] },
              }
            ]
          },
          {
            path: 'tickets',
            name: 'Admin Tickets',
            component: () => import('@/admin/pages/Tickets.vue'),
            meta: { middleware: [middleware] },
            redirect: { name: 'Admin Ticket List' },
            children: [
              {
                path: '/',
                name: 'Admin Ticket List',
                component: () => import('@/admin/components/ticket/Table.vue'),
                meta: { middleware: [middleware] },
              },
              {
                path: ':uuid',
                name: 'Admin Ticket',
                component: () => import('@/admin/components/ticket/Ticket.vue'),
                meta: { middleware: [middleware] },
              },
            ]
          }
        ]
      },
    ]
  },
  /**
   * Zoom Frame outside Admin layout
   * 
   */
  {
    path: '/admin/zoom/:meeting_id/:password/:zoom_key/:zoom_secret/:email/:name',
    name: 'Admin Zoom Frame',
    component: () => import('@/admin/components/meeting/MeetingFrame.vue'),
    meta: { middleware: [middleware] },
  },
  {
    path: '/admin/zoom-ended',
    name: 'Admin Zoom Ended',
    component: () => import('@/admin/components/meeting/Ended.vue'),
    meta: { middleware: [middleware] },
  },

]