var moment = require('moment')

const helpers = {
  dateDiff(val) {
    return moment(val).fromNow()
  },
  dateFormat(val) {
    return moment(val).format('lll')
  },
  showPage() {
    return [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '50', value: 50 },
      { text: '100', value: 100 },
      { text: 'All', value: 0 },
    ]
  },
  showDate() {
    return ['Today', 'This Week', 'This Month', 'Past Month', 'Past 3 Months',]
  },
  durations() {
    return ['Hours', 'Days', 'Months',]
  },
  lessonType() {
    return ['Topic', 'Assessment',]
  },
  material() {
    return [
      { text: 'Youtube URL', value: 'u' },
      { text: 'Video', value: 'v' },
      { text: 'PDF/DOCs/PPT', value: 'd' },
      { text: 'Virtual Tour', value: 't' },
    ]
  },
  assessmentType() {
    return [
      { text: 'Mulitple Choice', value: 'mc' },
      { text: 'Identification', value: 'iden' },
      { text: 'True or False', value: 'tof' },
      { text: 'Essay', value: 'ess' },
      { text: 'Survey', value: 'sur' },
    ]
  },
  assessment(val) {
    switch (val) {
      case 'mc':
        return 'Multiple Choice'
      case 'iden':
        return 'Identification'
      case 'tof':
        return 'True or False'
      case 'ess':
        return 'Essay'
      case 'sur':
        return 'Survey'
      default:
        break;
    }
  },
  userLevel() {
    return [
      { text: 'PAO MEMBER', value: 1 },
      { text: 'PAO MEMBER-IN-TRAINING', value: 2 },
      { text: 'NON-MEMBER-OPHTHALMOLOGIST', value: 3 },
      { text: 'ALLIED PROFESSIONAL (NON-OPHTHA MDs,MEDICAL STUDENT)', value: 4 },
      { text: 'FOREIGN DELEGATE', value: 5 },
      { text: 'INDUSTRY PARTNER', value: 6 },
    ]
  },
  courseStatus(val) {
    switch (val) {
      case 0:
        return 'Not published'
      case 1:
        return 'Waiting for approval'
      case 2:
        return 'Approved'
      case 3:
        return 'Drafted'
      default:
        break;
    }
  },
  status() {
    return [
      { value: 0, text: 'Not Published' },
      { value: 1, text: 'Waiting for approval' },
      { value: 2, text: 'Approved' },
      { value: 3, text: 'Drafted' },
    ]
  },
  level(val) {
    switch (val) {
      case 1:
        return 'PAO MEMBER'
      case 2:
        return 'PAO MEMBER-IN-TRAINING'
      case 3:
        return 'NON-MEMBER-OPHTHALMOLOGIST'
      case 4:
        return 'ALLIED PROFESSIONAL (NON-OPHTHA MDs,MEDICAL STUDENTS)'
      case 5:
        return 'FOREIGN DELEGATE'
      case 6:
        return 'INDUSTRY PARTNER'
      default:
        break;
    }
  },
  specialty() {
    return ['Ophthalmology', 'Others', 'Not Applicable']
  },
  subspecialty() {
    return [
      'Cornea / External Disease / Anterior Segment',
      'Genetics',
      'Glaucoma',
      'Low Vision',
      'Neuro-Ophthalmology',
      'Ocular Pathology / Oncology',
      'Orbit and Oculoplasty',
      'Pediatric Ophthalmology and Strabismus',
      'Refractive Management / Intervention',
      'Retina / Vitreous',
      'Uveitis',
      'None',
      'Others'
    ]
  },
  authRole() {
    return ['a', 's', 't']
  },
  ticketCategory() {
    return [
      'Learning Material',
      'Design Platform',
      'Others',
    ]
  },
  ticketPrio() {
    return [
      { value: 1, text: 'Low' },
      { value: 2, text: 'Medium' },
      { value: 3, text: 'Urgent' },
    ]
  },
  prioType(val) {
    switch (val) {
      case 1:
        return 'Low'
      case 2:
        return 'Medium'
      case 3:
        return 'Urgent'
    }
  },
  prioColor(val) {
    switch (val) {
      case 1:
        return '#e5e5e5'
      case 2:
        return 'warning'
      case 3:
        return 'red'
    }
  },
  ticketStatus(val) {
    switch (val) {
      case 0:
        return 'Pending'
      case 1:
        return 'On going'
      case 2:
        return 'Done'
    }
  },
  ticketStat() {
    return [
      { value: 0, text: 'Pending' },
      { value: 1, text: 'On going' },
      { value: 2, text: 'Done' },
    ]
  },
  progressColor(val) {
    switch (val) {
      case 'start':
        return 'warning'
      case 'continue':
        return 'info'
      case 'completed':
        return 'success'  
    }
  },

  tenant() {
    let domain = process.env.VUE_APP_API
    // let port = process.env.VUE_APP_SOCKET_PORT

    let nohttp =  domain.replace('http://', '')
    
    return nohttp.replace('.mdpie.test', '')
  },

  socket_end_point() {
    let domain = process.env.VUE_APP_API
    let port = process.env.VUE_APP_SOCKET_PORT

    return `${domain}:${port}`
  }

}

export default {
  install(Vue) {
    Vue.prototype.$helpers = helpers
  }
}