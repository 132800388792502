import { axiosInstance } from "@/services/axios"

export default {
  async storeZoomApi({ commit }, payload) {
    await axiosInstance.post(`/api/admin/zoom/store`, payload)
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
      })
      .then((res) => {
        commit('authMutation', res.data)
      })
  },

  async getMeetings({ commit },) {
    await axiosInstance.get(`/api/admin/meeting`)
      .then((res) => {
        commit('meetingsMutation', res.data)
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
      })
  },

  async storeMeeting({ commit, dispatch }, payload) {
    await axiosInstance.post(`/api/admin/meeting/store`, payload)
      .then(() => {
        dispatch('getMeetings')
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
      })
  },

  async showMeeting({ commit }, payload) {
    console.log(payload);
    await axiosInstance.get(`/api/admin/meeting/${payload}`)
      .then((res) => {
        commit('meetingMutation', res.data)
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
      })
  },

  async deleteMeeting({ commit, dispatch }, payload) {
    await axiosInstance.get(`/api/admin/meeting/delete/${payload}`)
      .then(() => {
        dispatch('getMeetings')
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
      })
  },

  
  async getEmbeds({ commit } ) {
    await axiosInstance.get(`/api/admin/embed`)
      .then((res) => {
        commit('embedsMutation', res.data)
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
      })
  },
  
  async storeEmbed({ commit, dispatch }, payload) {
    await axiosInstance.post(`/api/admin/embed`,payload)
      .then((res) => {
        console.log(res.data);
        dispatch('getEmbeds')
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
      })
  },

  async startEmbed({ commit, dispatch }, payload ) {
    await axiosInstance.get(`/api/admin/embed/start/${payload}`)
      .then(() => {
        dispatch('getEmbeds')
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
      })
  },

  async stopEmbed({ commit, dispatch }, payload ) {
    await axiosInstance.get(`/api/admin/embed/stop/${payload}`)
      .then(() => {
        dispatch('getEmbeds')
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
      })
  },

  async deleteEmbed({ commit, dispatch }, payload ) {
    await axiosInstance.get(`/api/admin/embed/delete/${payload}`)
      .then(() => {
        dispatch('getEmbeds')
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
      })
  },



}