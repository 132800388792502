import { axiosInstance } from "@/services/axios"

export default {
  async storeLeaderboard({ commit, dispatch }, payload) {
    await axiosInstance.post(`/api/admin/leaderboard`, payload)
    .then((res) => {
      console.log(res.data);
      dispatch('getLeaderboard')
    }).catch((err) => {
      commit('errorsMutation', err.response.data.errors)
    });
    
  },
  async getLeaderboard({ commit }) {
    await axiosInstance.get(`/api/admin/leaderboard`)
    .then((res) => {
      commit('leaderboardsMutation', res.data)
    }).catch((err) => {
      commit('errorsMutation', err.response.data.errors)
    });
  },

  async showLeaderboard({ commit }, payload) {
    await axiosInstance.get(`/api/admin/leaderboard/${payload}`)
    .then((res) => {
      commit('leaderboardsMutation', res.data)
    }).catch((err) => {
      commit('errorsMutation', err.response.data.errors)
    });
  },

  async showStudentScores({ commit }, payload) {
    await axiosInstance.get(`/api/admin/leaderboard/student/${payload}`)
    .then((res) => {
      commit('scoresMutation', res.data)
    }).catch((err) => {
      commit('errorsMutation', err.response.data.errors)
    });
  },

  async resetStudentChat({ commit, dispatch }, payload) {
    await axiosInstance.get(`/api/admin/leaderboard/reset/${payload}`)
    .then((res) => {
      console.log(res.data);
      dispatch('showStudentScores', payload)
    }).catch((err) => {
      commit('errorsMutation', err.response.data.errors)
    });
  },

  async disqualifiedLeaderboard({ commit }, payload) {
    await axiosInstance.post(`/api/admin/leaderboard/disqualified`, payload)
    .then((res) => {
      console.log(res.data);
    }).catch((err) => {
      commit('errorsMutation', err.response.data.errors)
    });
  },

}