import store from '@/store'

function checker(name) {
  if(Object.entries(store.getters[`${name}/errorsGetters`]).length > 0) return true
  return false;
}

export default {
  install(Vue) {
    Vue.prototype.$errors = checker
  }
}