import { axiosInstance } from "@/services/axios"

export default {
  async showLessonsAction({ commit }, payload) {
    try {
      const res = await axiosInstance.get(`/api/teacher/lesson/show/${payload}`)
      commit('lessonsMutation', res.data)
    } catch (error) {
      commit('errorsMutation', error.response.data.errors)
    }  
  },
  async addLessonAction({ commit, dispatch }, payload) {
    try {
      await axiosInstance.post('/api/teacher/lesson/store', payload)
      dispatch('showLessonsAction', payload.course_id)
    } catch (error) {
      commit('errorsMutation', error.response.data.errors)
    }  
  },
  async deleteLessonAction({ commit, dispatch }, payload) {
    try {
      await axiosInstance.delete(`/api/teacher/lesson/${payload.lesson_id}`)
      dispatch('showLessonsAction', payload.course_id)
    } catch (error) {
      commit('errorsMutation', error.response.data.errors)
    }  
  },
  async sortLessonsAction({ commit }, payload) {
    try {
      await axiosInstance.post('/api/teacher/lesson/sort', payload)
    } catch (error) {
      commit('errorsMutation', error.response.data.errors)
    }  
  },
  async addLessonAssessmentAction({ commit }, payload) {
    try {
      await axiosInstance.post('/api/teacher/lesson/store-assessment', payload)
    } catch (error) {
      commit('errorsMutation', error.response.data.errors)
    }  
  },
  async statusLessonAction({ commit, dispatch }, payload) {
    try {
      await axiosInstance.get(`/api/teacher/lesson/status/${payload.lesson_id}`)
      dispatch('showLessonsAction', payload.course_id)
    } catch (error) {
      commit('errorsMutation', error.response.data.errors)
    }  
  },
  /***
   * Edit function for Lesson
   * with no relation
   * 
   */
  async showLesson({ commit }, payload) {
    await axiosInstance.get(`/api/teacher/lesson/edit/${payload}`)
    .then((res) => {
      commit('lessonMutation', res.data)
    })
    .catch((error) => {
      commit('errorsMutation', error.response.data.errors)
    }) 
  },
  async updateLesson({ commit, dispatch }, payload) {
    await axiosInstance.post(`/api/teacher/lesson/update`, payload.data)
    .then((res) => {
      console.log(res);
      dispatch('showLessonsAction', payload.course_id)
    })
    .catch((error) => {
      commit('errorsMutation', error.response.data.errors)
    }) 
  },
}