import Vue from 'vue'

import studentRoutes from '../constants/links/student-links'
import teacherRoutes from '../constants/links/teacher-links'
import adminRoutes from '../constants/links/admin-links'
import helpers from '../boot/helpers'
import errors from '../boot/error'

Vue.use(studentRoutes)
Vue.use(teacherRoutes)
Vue.use(adminRoutes)
Vue.use(helpers)
Vue.use(errors)