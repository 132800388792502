const routes = [
  {
    subheader: 'DASHBOARD',
    list: [
      // {
      //   title: 'Dashboard',
      //   icon: 'mdi-view-dashboard-outline',
      //   route: 'Teacher Dashboard'
      // },
      {
        title: 'My Courses',
        icon: 'mdi-book-multiple-outline',
        route: 'Teacher Courses'
      },
      // {
      //   title: 'Users',
      //   icon: 'mdi-account-multiple-outline',
      //   route: 'Teacher Students'
      // },
      // {
      //   title: 'Inbox',
      //   icon: 'mdi-message-outline',
      //   route: 'Teacher Inbox'
      // },
      // {
      //   title: 'Meetings',
      //   icon: 'mdi-video-outline',
      //   route: 'Teacher Meeting'
      // },
    ],
  },
  {
    subheader: 'ACCOUNTS',
    list: [
      {
        title: 'Profile',
        icon: 'mdi-account-outline',
        route: 'Teacher Profile'
      },
    ]
  },
]


export default {
  install(Vue) {
    Vue.prototype.$teacherRoutes = routes
  }
}



