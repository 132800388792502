import { axiosInstance } from "@/services/axios"

export default {
  async dashboard({ commit }) {
    await axiosInstance.get(`/api/student/dashboard`)
      .then((res) => {
        commit('dashboardMutation', res.data)
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
        return
      })
  },

  async theme({ commit, dispatch }) {
    await axiosInstance.get(`/api/student/theme`)
      .then(() => {
        dispatch('authAction')
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
        return
      })
  },

}