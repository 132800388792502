import { axiosInstance } from "@/services/axios"

export default {
  async registerAction({ commit }, payload) {
    try {
      await axiosInstance.post('/api/student/register', payload)
    } catch (error) {
      commit('errorsMutation', error.response.data.errors)
    }
  },

  async loginAction({ commit }, payload) {
    try {
      const res = await axiosInstance.post('/api/student/login', payload)
      commit('signinMutation', res.data)
    } catch (error) {
      commit('errorsMutation', error.response.data.errors)
    }
  },

  async authAction({ commit }) {
    const res = await axiosInstance.get('/api/student/details')
    .catch(error => {
      if(error.response.status === 401 || error.response.status === 500) {
        localStorage.removeItem('access_key')
        alert('Relogin your account.')      
        location.href='/login'
      }   
    })
    commit('authMutation', res.data)
  },

  async logoutAction({ commit }) {
    await axiosInstance.get('/api/student/logout')
    commit('signoutMutation') 
  },

  async newPasswordAction({ commit }, payload) {
    try {
      await axiosInstance.post('/api/student/account/new-password', payload)
    } catch (error) {
      commit('errorsMutation', error.response.data.errors)
    }  
  },

  async resetPassword({ commit }, payload) {
    await axiosInstance.post(`/api/student/reset`, payload)
      .then((res) => {
        console.log(res.data);
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
        return
      })
  },
}
 

