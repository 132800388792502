import router from "@/router"

export function signinMutation(state, payload) {
	let keyObject = { token: payload.access_token, role: 's' }

	localStorage.setItem('access_key', JSON.stringify(keyObject))
	state.user = payload
	router.push({ name: 'Student Course Overview' })
}

export function signoutMutation(state) {
	localStorage.removeItem('access_key')
	state.user = null
	location.href = '/'
}

export function authMutation(state, payload) {
	state.user = payload
}

export function errorsMutation(state, payload) {
	state.errors = payload
	//--- clear after 2 sec
	setTimeout(() => {
		state.errors = []
	}, 2000)
}

export function resetPageMutation(state) {
	state.date = ''
	state.query = ''
	state.count = 10
}

export function coursesPageMutation(state, payload) {
	state.courses.current_page = payload
}
//---plural
export function coursesMutation(state, payload) {
	state.courses = payload
}
//--singular
export function courseMutation(state, payload) {
	state.course = payload
}

export function lessonsMutation(state, payload) {
	state.lessons = payload
}

export function topicMutation(state, payload) {
	state.topic = payload
}

export function assessmentMutation(state, payload) {
	state.assessment = payload
}

export function addChoicesMutation(state, payload) {
	state.assessment.questions.forEach((item) => {
		if (item.id == payload) {
			item.choices.push('')
		}
	})
}

export function removeChoiceMutation(state, payload) {
	state.assessment.questions.forEach((item) => {
		if (item.id == payload.id) {
			item.choices.splice(payload.index, 1)
		}
	})
}

export function meetingMutation(state, payload) {
	state.meeting = payload
}

export function meetingsMutation(state, payload) {
	state.meetings = payload
}

export function ticketsMutation(state, payload) {
	state.tickets = payload
}

export function dashboardMutation(state, payload) {
	state.dashboard = payload
}

export function commentsMutation(state, payload) {
	state.comments = payload
}

export function sponsorsMutation(state, payload) {
	state.sponsors = payload
}

export function chatMutation(state, payload) {
	state.chat = payload
}

export function exhibitsMutation(state, payload) {
	state.exhibits = payload
}

export function leaderboardsMutation(state, payload) {
	state.leaderboards = payload
}

export function embedsMutation(state, payload) {
	state.embeds = payload
}

export function embedMutation(state, payload) {
	state.embed = payload
}

export function embedCommentsMutation(state, payload) {
	state.embed_comments = payload
}


export function scoreMutation(state, payload) {
	state.score = payload
}
