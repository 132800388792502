export const LandingRoutes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('@/landing/pages/Index.vue'),
    redirect: { name: 'Login' }
  },
  // {
  //   path: '/registration',
  //   name: 'Registration',
  //   component: () => import('@/authentication/RegisterStudent.vue')
  // },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/authentication/LoginStudent.vue')
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: () => import('@/authentication/ForgotPassword.vue')
  },
  // {
  //   path: '/executive-council',
  //   name: 'Executive',
  //   component: () => import('@/landing/pages/Executive.vue')
  // },
  // {
  //   path: '/scientific-program/:tab',
  //   name: 'Program',
  //   component: () => import('@/landing/pages/Program.vue')
  // },
  
]