export default function () {
  return {
    user: [],
    dashboard: [],
    courses: [],
    course: [],

    tickets: [],
    embeds: [],
    embed: [],
    embed_comments: [],
    meeting: [],
    meetings: [],

    comments: [],

    topic: [],
    assessment: [],

    sponsors: [],

    chat: [],

    exhibits: [],
    leaderboards: [],

    score: [],

    date: '',
    query: '',
    count: 10,
    status: '',

    errors: [],
  }
}