import state from './state'
import * as getters from './getters'
import * as mutations from './mutations'
import main from './actions'
import course from './actions/course'
import topic from './actions/topic'
import lesson from './actions/lesson'
import assessment from './actions/assessment'
import student from './actions/student'
import chat from './actions/chat'

export default {
  namespaced: true,
  getters,
  mutations,
  actions: {
    ...main,
    ...course,
    ...lesson,
    ...topic,
    ...assessment,
    ...student,
    ...chat
  },
  state
}