import { axiosInstance } from "@/services/axios"

export default {
  async getCoursesAction({ commit }, payload) {
    const res = await axiosInstance.get(`/api/admin/course/?
      page=${payload.page}
      &query=${payload.query}
      &status=${payload.status}
      &date=${payload.date}
      &count=${payload.count}`)

    commit('coursesMutation', res.data)
  },

  async allCoursesAction({ commit }) {
    const res = await axiosInstance.get(`/api/admin/course/all`)
    commit('coursesMutation', res.data)
  },

  async showCourseAction({ commit }, payload) {
    const res = await axiosInstance.get(`/api/admin/course/show/${payload}`)
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
        return
      })
    commit('courseMutation', res.data)
  },

  async statusCourseAction({ commit, dispatch }, payload) {
    await axiosInstance.post(`/api/admin/course/status`, payload)
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
        return
      })
    dispatch('showCourseAction', payload.uuid)
  },

  async studentProgressAction({ commit }, payload) {
    await axiosInstance.get(`/api/admin/course/progress/${payload}`)
      .then((res) => {
        commit('coursesMutation', res.data)
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
        return
      })

  },

  async studentsReportAction({ commit }, payload) {
    await axiosInstance.get(`/api/admin/course/users-report/${payload}`)
      .then((res) => {
        // commit('coursesMutation', res.data)
        console.log(res.data)
        // let fileURL = URL.createObjectURL(new Blob([res.data]));
        let fileLink = document.createElement('a')

        fileLink.href = res.data
        fileLink.setAttribute('download', `@${new Date()}.xlsx`)
        document.body.appendChild(fileLink)

        fileLink.click()
        fileLink.remove()
    })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
        return
      })

  },
}