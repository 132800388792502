import { middleware } from './user.middleware'

export const TeacherRoutes = [
  {
    path: '/teacher',
    component: () => import('@/teacher/layouts/Main.vue'),
    redirect: { name: 'Teacher Dashboard' },
    meta: { middleware: [middleware] },
    children: [
      {
        path: '/teacher/dashboard',
        name: 'Teacher Dashboard',
        component: () => import('@/teacher/pages/Dashboard.vue'),
        meta: { middleware: [middleware] },
      },
      {
        path: '/teacher/my-courses',
        name: 'Teacher Courses',
        component: () => import('@/teacher/pages/course/Courses.vue'),
        meta: { middleware: [middleware] },
      },
      {
        path: '/teacher/edit-courses/:uuid',
        name: 'Teacher Edit Course',
        component: () => import('@/teacher/pages/course/EditCourse.vue'),
        meta: { middleware: [middleware] },
      },
      {
        path: '/teacher/topic/:uuid',
        name: 'Teacher Topic',
        component: () => import('@/teacher/pages/Topic.vue'),
        meta: { middleware: [middleware] },
      },
      {
        path: '/teacher/assessment/:uuid',
        name: 'Teacher Assessment',
        component: () => import('@/teacher/pages/Assessment.vue'),
        meta: { middleware: [middleware] },
      },
      {
        path: '/teacher/profile',
        name: 'Teacher Profile',
        component: () => import('@/teacher/pages/Profile.vue'),
        meta: { middleware: [middleware] },
      },
      {
        path: '/teacher/inbox',
        name: 'Teacher Inbox',
        component: () => import('@/teacher/pages/Inbox.vue'),
        meta: { middleware: [middleware] },
      },
      {
        path: '/teacher/meetings',
        name: 'Teacher Meeting',
        component: () => import('@/teacher/pages/Meeting.vue'),
        meta: { middleware: [middleware] },
      },
      {
        path: '/teacher/my-students',
        name: 'Teacher Students',
        component: () => import('@/teacher/pages/Students.vue'),
        meta: { middleware: [middleware] },
      },
      {
        path: '/teacher/student/:uuid',
        name: 'Teacher Student Progress',
        component: () => import('@/teacher/components/student/Progress.vue'),
        meta: { middleware: [middleware] },
      },
    ]
  }
]