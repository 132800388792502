import { axiosInstance } from "@/services/axios"

export default {
  async getUserCategory({ commit }) {
    await axiosInstance.get(`/api/admin/setting/user/category`)
      .then((res) => {
        console.log(res.data);
        commit('userCategoryMutation', res.data)
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
      })
  },

  async storeUserCategory({ commit, dispatch }, payload) {
    await axiosInstance.post(`/api/admin/setting/user/category`, payload)
      .then((res) => {
        console.log(res.data);
        dispatch('getUserCategory')
      })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
      })
  },
}