export default function () {
  return {
    user: [],

    students: [],
    progress: [],

    courses: [],
    course: [],

    lessons: [],
    lesson: [],
    topic: [],

    chat: [],
    convo: [],

    assessment: [],

    date: '',
    query: '',
    count: 10,
    status: '',
    errors: [],
  }
}