import state from './state'
import * as getters from './getters'
import * as mutations from './mutations'
import main from './actions'
import course from './actions/course'
import topic from './actions/topic'
import assessment from './actions/assessment'
import meeting from './actions/meeting'
import ticket from './actions/ticket'
import dashboard from './actions/dashboard'
import comment from './actions/comment'
import account from './actions/account'
import sponsor from './actions/sponsor'
import chat from './actions/chat'
import exhibit from './actions/exhibit'
import leaderboard from './actions/leaderboard'

export default {
  namespaced: true,
  getters,
  mutations,
  actions: {
    ...main,
    ...course,
    ...topic,
    ...assessment,
    ...meeting,
    ...ticket,
    ...dashboard,
    ...comment,
    ...account,
    ...sponsor,
    ...chat,
    ...exhibit,
    ...leaderboard
  },
  state
}