import { axiosInstance } from "@/services/axios"

const paging = () => {
  return {
    page: 0,
    query: '',
    date: '',
    count: 10
  }
}

export default {
  async getStudentsAction({ commit }, payload) {
    const res = await axiosInstance.get(`/api/admin/student/?
    page=${payload.page}
    &query=${payload.query}
    &date=${payload.date}
    &count=${payload.count}`)

    commit('studentsMutation', res.data)
  },

  async showStudentAction({ commit }, payload) {
    const res = await axiosInstance.get(`/api/admin/student/${payload}`)
    commit('studentMutation', res.data)
  },

  async addStudentAction({ commit, dispatch }, payload) {
    await axiosInstance.post('/api/admin/student/add', payload)
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
        return
      })
    dispatch('getStudentsAction', paging())
  },

  async deleteStudentAction({ commit, dispatch }, payload) {
    await axiosInstance.delete(`/api/admin/student/delete/${payload}`)
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
        return
      })
    console.log(paging())
    dispatch('getStudentsAction', paging())
  },

  async getTeacherAction({ commit }, payload) {
    const res = await axiosInstance.get(`/api/admin/teacher/?
    page=${payload.page}
    &query=${payload.query}
    &date=${payload.date}
    &count=${payload.count}`)

    commit('teacherMutation', res.data)
  },

  async bulkStudentAction({ commit, dispatch }, payload) {
    await axiosInstance.post('/api/admin/student/bulk', payload)
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
        return
      })

    dispatch('getStudentAction', paging)
  },

  async addTeacherAction({ commit, dispatch }, payload) {
    await axiosInstance.post('/api/admin/teacher/add', payload)
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
        return
      })

    dispatch('getTeacherAction', paging)
  },

  async deleteTeacherAction({ commit, dispatch }, payload) {
    await axiosInstance.delete(`/api/admin/teacher/delete/${payload}`)
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
        return
      })

    dispatch('getTeacherAction', paging)
  },

  async typeTeacherAction({ commit }, payload) {
    await axiosInstance.post(`/api/admin/teacher/type`, payload)
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
        return
      })

    // dispatch('getTeacherAction', paging)
  },

  /**
   * Retrieve all student per level
   * in creating voucher
   * 
   */
  async getStudentLevelAction({ commit }, payload) {
    const res = await axiosInstance.post(`/api/admin/student/level`, payload)
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
        return
      })

    commit('studentsMutation', res.data)
  },
  /** 
   * Recycle Bin Action
   * 
   * 
  */
  async getTrashedStudentsAction({ commit }) {
    await axiosInstance.get(`/api/admin/student/deleted`)
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
      })
      .then((res) => {
        commit('studentsMutation', res.data)
      })
  },
  /**
   * Softdeletes Account Action
   * 
   */
  async recoverStudents({ commit }, payload) {
    await axiosInstance.post(`/api/admin/student/restore`, payload)
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
        return
      })
  },

  async deleteStudentsPermanently({ commit, dispatch }, payload) {
    await axiosInstance.post(`/api/admin/student/permanently-delete`, payload)
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
        return
      })
      .then(() => {
        dispatch('getTrashedStudentsAction')
      })
  },

  /***
   * Export Accounts
   * 
   */
  async exportStudents({ commit }) {
    await axiosInstance.post(`/api/admin/student/export`, {
      responseType: 'blob'
    })
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
      })
      .then((res) => {
        console.log(res.data)
        // let fileURL = URL.createObjectURL(new Blob([res.data]));
        let fileLink = document.createElement('a')

        fileLink.href = res.data
        fileLink.setAttribute('download', `@${new Date()}-student-list.xlsx`)
        document.body.appendChild(fileLink)

        fileLink.click()
        fileLink.remove()
      })
  },

  /**
   * reset user/student level
   * @param {*} param0 
   * @param {*} payload 
   */
  async studentLevelChange({ commit, dispatch }, payload) {
    await axiosInstance.post(`/api/admin/student/level-change`, payload)
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
        return
      })
      .then(() => {
        dispatch('getStudentsAction', paging())

      })
  },

  /**
   * reset user/student password to pao2021
   * @param {*} param0 
   * @param {*} payload 
   */
  async studentDefaultPassword({ commit, dispatch }, payload) {
    await axiosInstance.post(`/api/admin/student/default-password`, payload)
      .catch(err => {
        commit('errorsMutation', err.response.data.errors)
        return
      })
      .then(() => {
        dispatch('getStudentsAction', paging())

      })
  },

}