export function userGetters (state) {
  return state.user
}

export function errorsGetters (state) {
  return state.errors
}

export function countGetters (state) {
  return state.count
}

export function coursesGetters (state) {
  return state.courses
}